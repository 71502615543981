import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/final/sarah.jpg'
import ts2 from '../../images/final/john.jpg'
import ts3 from '../../images/final/emily.jpg'


class Testimonial extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
        };

        const testimonial = [
            {
                tsImg: ts1,
                Des: "Mackenzie & Wesonga Advocates provided exceptional legal representation in our real estate transactions. Their expertise and dedication ensured smooth dealings and favorable outcomes.",
                Title: 'Sarah Kimani',
                Sub: "Property Investor",
            },
            {
                tsImg: ts2,
                Des: "In litigation matters, Mackenzie & Wesonga Advocates stood by us every step of the way. Their knowledgeable team navigated complex legal issues with precision, securing victory for us.",
                Title: 'John Mutuku',
                Sub: "Business Owner",
            },
            {
                tsImg: ts3,
                Des: "We entrusted Mackenzie & Wesonga Advocates with our employment law concerns, and they exceeded our expectations. Their proactive approach and sound advice helped resolve disputes effectively.",
                Title: 'Emily Atieno',
                Sub: "HR Manager",
            }
        ]
        return (
            <section className="wpo-testimonials-section section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <div className="wpo-section-title">
                                <h2>What Our Clients Say About Us</h2>
                                <p>Discover why clients trust Mackenzie & Wesonga Advocates for unparalleled legal expertise, 
                                    dedicated representation, and successful outcomes.</p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="wpo-testimonial-wrap owl-carousel">
                                <Slider {...settings}>
                                    {testimonial.map((tesmnl, tsm) => (
                                        <div className="wpo-testimonial-item" key={tsm}>
                                            <p>{tesmnl.Des}</p>
                                            <div className="wpo-testimonial-info">
                                                <div className="wpo-testimonial-info-img">
                                                    <img src={tesmnl.tsImg} alt="" />
                                                </div>
                                                <div className="wpo-testimonial-info-text">
                                                    <h5>{tesmnl.Title}</h5>
                                                    <span>{tesmnl.Sub}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonial;