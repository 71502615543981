import React from 'react'

const Features2 = (props) => {


    const features = [
        {
           fIcon:'fi flaticon-badge',
           title:'Effectiveness',
           des:'We prioritize client needs, meet deadlines, and maintain quality, maximizing efficiency in our delivery process.'
        },
        {
           fIcon:'fi flaticon-diary',
           title:'Client Satisfaction',
           des:'Our focus on client satisfaction ensures we provide unparalleled legal solutions to their problems and challenges.'
        },
        {
           fIcon:'fi flaticon-support',
           title:'Accountability',
           des:'Transparent, accountable, bound by core values to clients and ourselves, ensuring trust and integrity.'
        },
        
    ]


    return(
        <section className="wpo-features-section-s2 section-padding">
            <div className="container">
                <div className="wpo-features-wrapper">
                    <div className="row">
                        {features.map((features, fitem) => (
                            <div className="col-lg-4 col-md-6 col-12" key={fitem}>
                                <div className="wpo-features-item">
                                    <div className="wpo-features-icon">
                                        <div className="icon">
                                            <i className={features.fIcon}></i>
                                        </div>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h2>{features.title}</h2>
                                        <p>{features.des}</p>
                                    </div>
                                    <div className="visible-icon">
                                        <i className={features.fIcon}></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features2;