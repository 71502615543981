import atimg1 from '../images/final/mck.jpg'
import atimg2 from '../images/final/wes.jpg'
import atimg3 from '../images/final/tk.jpg'
import atimg4 from '../images/final/ivy.jpg'

const Attorneys = [
    {
       Id:'1',
       AtImg:atimg1,
       name:'Mark Mackenzie',
       title:'Partner',   
       experience: '5 years',
       address:'12344',
       phone:'0777 116 394',
       email:'mck@mackenziewesongalaw.com',
       desc:'Mark Mackenzie, our seasoned Partner, brings 5 years of expertise in corporate and commercial law, providing unmatched legal solutions to our esteemed clientele. With a keen eye for detail and a dedication to excellence, Mark ensures that our clients receive comprehensive legal advice tailored to their specific needs. He has successfully handled numerous complex transactions and litigations, earning the trust and respect of both clients and peers alike.',
    },
    {
       Id:'2',
       AtImg:atimg2,
       name:'Rodney Wesonga',
       title:'Partner',  
       experience: '5 years',
       address:'12344',
       phone:'0712 696 577',
       email:'wes@mackenziewesongalaw.com',
       desc:'Rodney Wesonga, Partner extraordinaire, leverages 5 years of experience in finance and banking law to ensure our clients receive top-notch legal representation and advisory services. With a strong track record of success in handling intricate financial transactions and regulatory matters, Rodney is known for his strategic approach and meticulous attention to detail. He works tirelessly to protect our clients\' interests and achieve favorable outcomes in even the most challenging cases.',
    },
    {
       Id:'3',
       AtImg:atimg3,
       name:'Trevor Kiyaka',
       title:'Business Development Manager',
       experience: '5 years',
       address:'12344',
       phone:'0782 337 448',
       email:'tk@mackenziewesongalaw.com',
       desc:'Trevor Kiyaka, our dynamic Business Development Manager, harnesses 5 years of experience to foster strong client relationships and drive business growth, ensuring our firm remains at the forefront of legal excellence. With a passion for networking and a deep understanding of market dynamics, Trevor identifies opportunities for expansion and develops innovative strategies to attract new clients. He plays a pivotal role in enhancing the firm\'s reputation and securing valuable partnerships, contributing significantly to our continued success.',
    },
    {
       Id:'4',
       AtImg:atimg4,
       name:'Ivy Njeri',
       title:'Administrator',
       experience: '5 years',
       address:'12344',
       phone:'0711 222 333',
       email:'ivy@@mackenziewesongalaw.com',
       desc:'Ivy Njeri, our proficient Administrator, utilizes 5 years of administrative prowess to ensure seamless operations and efficient management of our law firm. With meticulous organizational skills and a proactive approach, Ivy oversees daily administrative tasks and streamlines internal processes to optimize productivity. She is adept at managing schedules, coordinating meetings, and handling correspondence, enabling our team to focus on delivering exceptional legal services to our clients. Ivy\'s dedication and professionalism are instrumental in maintaining a positive work environment and upholding our firm\'s reputation for excellence.',
    }
]

export default Attorneys;
