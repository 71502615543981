
import simg from '../images/final/realestate.jpg'
import simg2 from '../images/final/litigation.jpg'
import simg3 from '../images/final/employment.jpg'
import simg4 from '../images/final/ip.jpg'
import simg5 from '../images/final/dispute.jpg'
import simg6 from '../images/final/corporate.jpg'
import simg7 from '../images/final/finance.jpg'
import simg8 from '../images/final/family.jpg'
import simg9 from '../images/final/legislative.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'


const Services = [
    {
        Id: '1',
        sImg:simg,
        sTitle: 'Real Estate Law',
        description:'From transactions to disputes, we provide legal advice and represent clients in litigation, ensuring smooth property dealings.',
        des2:'Manage transactions, disputes, provide legal counsel in litigation.',
        des3:' Facilitate transactions, resolve disputes, offer legal counsel, represent in litigation, ensuring smooth property dealings and transparency .',
        descf:'Our firm is adept at navigating the intricacies of property transactions and real estate development projects. From assisting clients in acquiring properties to guiding them through regulatory requirements, we ensure smooth and legally sound dealings. Our services extend to representing developers, property owners, and investors in various transactions, offering expert advice on land use, zoning, and contractual matters. With a focus on maximizing client goals and minimizing legal hurdles, we strive to facilitate seamless real estate transactions and project completions.',
        icon:'fi flaticon-broken-arm',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,

    },
    {
        Id: '2',
        sImg:simg2,
        sTitle: 'Litigation',
        description:'Offering comprehensive representation in courts, advising on pleadings, ensuring compliance, and handling competition law matters.',
        des2:'Offer representation, legal advice, ensure compliance, handle competition.',
        des3:'Provide comprehensive representation, legal advice, ensure compliance, handle competition law matters, advocate in courts.',
        descf:'With a dedicated team of legal professionals, we offer robust representation in courts and tribunals across multiple legal domains. Our services encompass preparing pleadings, providing strategic legal advice, and handling appellate litigation. Whether clients are plaintiffs, defendants, claimants, or respondents, we diligently advocate for their interests while ensuring compliance with legal procedures and regulations. From corporate litigation to matters involving compliance, taxation, and competition law, our expertise spans diverse areas, providing clients with comprehensive legal support and effective representation in all stages of litigation.',
        icon:'fi flaticon-family',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Employment Law',
        description:'Drafting contracts, resolving disputes, negotiating benefits, and facilitating collective bargaining for employers and employees.',
        des2:'Draft contracts, mediate disputes, negotiate benefits, ensure fairness.',
        des3:'Draft contracts, mediate disputes, negotiate benefits, facilitate collective bargaining, ensuring fair employment practices.',
        descf:'We specialize in providing comprehensive legal support to both employers and employees across various sectors. Our services cover the entire spectrum of employment law, including drafting and reviewing employment contracts, resolving disputes, and ensuring compliance with labour regulations. From advising on employee benefits to representing clients in cases of discrimination, harassment, and wrongful termination, we strive to protect the rights and interests of all parties involved. Our expertise extends to collective bargaining, mediation, and alternative dispute resolution, fostering fair and amicable solutions to employment-related conflicts.',
        icon:'fi flaticon-handcuffs',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sTitle: 'Intellectual Property Law',
        description:'Protecting patents, trademarks, copyrights, providing litigation support, and strategic advice to safeguard intellectual assets.',
        des2:'Protect patents, trademarks, offer litigation support.',
        des3:'Protect patents, trademarks, copyrights, provide litigation support, strategic advice, safeguarding clients intellectual assets with excellence.',
        descf:'Our firm offers extensive expertise in intellectual property law, focusing on protecting and preserving clients valuable innovations and creations. From registration and enforcement to litigation and strategic advice, we provide comprehensive legal support across all aspects of intellectual property rights. Our services include advising on patents, trademarks, copyrights, trade secrets, and domain names, ensuring robust protection against infringement and misappropriation. With a commitment to safeguarding clients intellectual assets, we help businesses and individuals navigate the complex landscape of intellectual property law while maximizing the value of their innovations.',
        icon:'fi flaticon-graduation-cap',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sTitle: 'Dispute Resolution',
        description:'Specializing in arbitration, mediation, negotiation, and conciliation to resolve commercial, private, and marital disputes efficiently.',
        des2:'Specialize in arbitration, mediation, negotiation.',
        des3:'Specialize in arbitration, mediation, negotiation, and conciliation to resolve commercial, private, and marital disputes efficiently.',
        descf:'Recognizing the importance of efficient and effective dispute resolution, we specialize in offering alternative dispute resolution services tailored to meet our clients needs. Our expertise includes arbitration, mediation, negotiation, and conciliation, providing parties with flexible and cost-effective methods for resolving disputes outside of traditional court proceedings. Whether dealing with commercial conflicts, personal disputes, or marital issues, we facilitate constructive dialogue and mutual agreement, striving to achieve timely and mutually beneficial resolutions. With a focus on promoting cooperation, understanding, and sustainable outcomes, we empower clients to navigate disputes with confidence and clarity.',
        icon:'fi flaticon-auction',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sTitle: 'Corporate Law',
        description:'Tailored legal solutions for transactions, compliance, agreements, and addressing competition law concerns for businesses.',
        des2:'Tailor legal solutions, ensure compliance, support businesses.',
        des3:'Tailor legal solutions for transactions, ensure compliance, draft agreements, address competition law concerns, supporting businesses.',
        descf:'Our firm provides comprehensive legal services to corporate clients, addressing a wide range of commercial transactions and regulatory matters. From corporate structuring and governance to contract drafting and compliance, we offer tailored solutions to meet the unique needs of businesses. Our expertise extends to mergers and acquisitions, joint ventures, and supply chain agreements, enabling clients to navigate complex transactions with confidence. With a deep understanding of corporate law principles and market dynamics, we help businesses achieve their strategic objectives while mitigating legal risks and ensuring regulatory compliance.',
        icon:'fi flaticon-balance',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:simg7,
        sTitle: 'Finance Law',
        description:'Assisting with transactions, lending, ensuring compliance with regulations, and providing representation in financial matters.',
        des2:'Assist with transactions, lending, ensure compliance.',
        des3:'Assist with transactions, lending, ensure compliance with regulations, provide representation in financial matters with transparency.',
        descf:'With extensive experience in finance and banking law, we offer specialized legal advice and support to clients involved in diverse financial transactions. Our services cover secured financing, corporate lending, due diligence, securitization, and real estate finance, catering to lenders, borrowers, and other stakeholders in the financial sector. Whether structuring complex financing arrangements, drafting legal documents, or ensuring compliance with regulatory requirements, we provide practical solutions that align with our clients objectives. By combining legal expertise with industry insights, we help clients navigate the complexities of finance and banking law while mitigating risks and maximizing opportunities.',
        icon:'fi flaticon-money-bag',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:simg8,
        sTitle: 'Family Law',
        description:'Offer comprehensive services for probate, divorce, custody, wills, and disputes, guiding clients through sensitive family legal issues.',
        des2:'Provide services for probate, divorce, custody, disputes.',
        des3:'Offer comprehensive services for probate, divorce, custody, wills, and disputes, guiding clients through sensitive family legal issues.',
        descf:'Our firm provides compassionate and comprehensive legal support to individuals and families facing probate, administration, and family law matters. From navigating the complexities of estate administration to resolving sensitive family disputes, we offer tailored solutions to meet our clients needs. Our services include will drafting, probate administration, estate planning, and representation in divorce, custody, and adoption proceedings. With a focus on achieving fair and equitable outcomes, we guide clients through challenging legal processes with empathy, integrity, and professionalism, helping them navigate lifes transitions with confidence and peace of mind.',
        icon:'fi flaticon-family',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '9',
        sImg:simg9,
        sTitle: 'Legislative Drafting',
        description:'Legislative Drafting: Collaborating with governments to draft policies, bills, provide legal advice, ensuring adherence to regulatory frameworks.',
        des2:'Legislative Drafting: Collaborate on policies, bills, ensure compliance.',
        des3:'Collaborate with governments to draft policies, bills, provide legal advice, ensuring adherence to regulatory frameworks.',
        descf:'Partnering with governments at both the national and local levels, we offer specialized expertise in legislative drafting and policy development. Our services encompass receiving, reviewing, and acting on drafting instructions, as well as drafting bills and legislative documents in accordance with constitutional principles and regulatory frameworks. From advising on legal matters to providing strategic guidance on policy formulation, we support government agencies and policymakers in developing effective laws and regulations. By combining legal acumen with a deep understanding of public policy issues, we help shape the legal landscape and promote good governance for the benefit of society as a whole.',
        icon:'fi flaticon-family',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]

export default Services;
       
