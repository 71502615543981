import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo1.svg'
import Services from '../../api/service';


import ins2 from '../../images/ft-icon/2.png'
import ins3 from '../../images/ft-icon/3.png'



const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt="logo"/>
                            </div>
                            <p>At Mackenzie & Wesonga Advocates, we specialize in providing tailored legal solutions across diverse fields
                                 including corporate, finance, family, litigation, real estate, and intellectual property law.
                                  </p>
                            
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Our Services </h3>
                            </div>
                            <ul>
                                {Services.slice(1, 7).map((service, Sitem) => (
                                    <li key={Sitem}><Link onClick={ClickHandler} to={`/practice-single/${service.Id}`}>{service.sTitle}</Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget tag-widget">
                            <div className="widget-title">
                                <h3>Browse by Tag </h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/practice-single/1">Real Estate</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/2">Litigation</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/3">Employment</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/4">Intellectual Property</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/5">Dispute Resolution</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/6">Corporate</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/7">Finance</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/8">Family</Link></li>
                                <li><Link onClick={ClickHandler} to="/practice-single/8">Legislative Drafting</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget social-widget">
                            <div className="widget-title">
                                <h3>Social Media</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/"><i><img src={ins2} alt=""/></i> Twitter</Link></li>
                                <li><Link onClick={ClickHandler} to="/"><i><img src={ins3} alt=""/></i> TikTok</Link></li>   
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> Copyright &copy; 2024 Designed by <Link onClick={ClickHandler} to="/">Trevor Kiyaka</Link>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;