import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom'
import Attorneys from '../../api/attorney';
import Footer from '../../components/footer'
import Logo from '../../images/logo.svg'


const AttorneySinglePage = (props) => {
    const { id } = useParams()

    const AttorneysDetails = Attorneys.find(item => item.Id === id)

    const SubmitHandler = (e) => {
        e.preventDefault()
    }



    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={AttorneysDetails.name} pagesub={AttorneysDetails.title} />
            <div className="attorney-pg-area section-padding">
                <div className="container">
                    <div className="attorney-info-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="attorney-info-img">
                                    <img src={AttorneysDetails.AtImg} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="attorney-info-text">
                                    <h2>{AttorneysDetails.name}</h2>
                                    <ul>
                                        <li>Practice Area:<span>{AttorneysDetails.title}</span></li>
                                        <li>Experience:<span>{AttorneysDetails.experience}</span></li>
                                         <li>Phone number:<span>{AttorneysDetails.phone}</span></li>
                                         <li>Email Address:<span>{AttorneysDetails.email}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exprience-area">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="exprience-wrap">
                                    <h2>Personal Experience</h2>
                                    <p>{AttorneysDetails.desc}</p>
                                </div>
                                <div className="language-area ex-wiget">
                                    <h2>Language</h2>
                                    <ul>
                                        <li>Kiswahili(fluent), English (fluent)</li>
                                    </ul>
                                </div>
                                <div className="wpo-contact-area ex-wiget">
                                    <h2>Contact Me</h2>
                                    <div className="quote-form">
                                        <form onSubmit={SubmitHandler}>
                                            <div className="form-group half-col">
                                                <input type="text" className="form-control" placeholder="Name:" name="name" />
                                            </div>
                                            <div className="form-group half-col">
                                                <input type="email" className="form-control" placeholder="Email:" name="email" />
                                            </div>
                                            <div className="form-group half-col">
                                                <input type="text" className="form-control" placeholder="Subject:" name="subject" />
                                            </div>
                                            <div className="form-group half-col">
                                                <input type="text" className="form-control" placeholder="Your Address:" name="address" />
                                            </div>
                                            <div className="form-group full-col">
                                                <textarea className="form-control" name="note" placeholder="Description..."></textarea>
                                            </div>
                                            <div className="form-group full-col">
                                                <button className="btn theme-btn" type="submit">Get In Touch</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default AttorneySinglePage;
